import React, {
  createContext,
  ReactElement,
  useContext,
  useEffect,
  useState
} from 'react';
import { List, ListItem, ListItemText, Typography } from '@mui/material';
import { usePathname } from 'next/navigation';

export type FaqProviderProps = {
  expandedItem: number | null;
  setExpandedItem: (index: number | null) => void;
  faqs: Faq[];
  openFaqItem: (tag: string) => void;
};

const defaultFaqProps: FaqProviderProps = {
  expandedItem: null,
  faqs: [],
  openFaqItem: (_: string) => {},
  setExpandedItem: (_: number | null) => {}
};
export const FaqContext = createContext<FaqProviderProps>(defaultFaqProps);

export function FaqProvider({ children }: React.PropsWithChildren) {
  const pathname = usePathname();
  const [faqs, setFaqs] = useState<Faq[]>([]);

  const [expandedItem, setExpandedItem] = useState<number | null>(null);

  useEffect(() => {
    const isBatterySolar = pathname.includes('battery');
    const isCharger = pathname.includes('charger');
    if (!isBatterySolar && !isCharger) {
      setFaqs([]);
    }
    if (isCharger) {
      setFaqs(chargerFaqs);
    }
    if (isBatterySolar) {
      setFaqs(batterySolarFaqs);
    }
  }, [pathname]);

  const openFaqItem = (tag: string) => {
    const filtered = faqs
      .map((value, index) => ({ value, index }))
      .filter(({ value }) => value.tag === tag);
    if (filtered.length === 0) return;
    setExpandedItem(filtered[0].index);
    window.location.href = `#${tag}`;
  };

  return (
    <FaqContext.Provider
      value={{ expandedItem, faqs, setExpandedItem, openFaqItem }}
    >
      {children}
    </FaqContext.Provider>
  );
}

export function useFaq() {
  return useContext(FaqContext);
}

export type Faq = {
  tag?: string;
  title: string;
  content: ReactElement;
};

const chargerFaqs: Faq[] = [
  {
    title: 'What is the difference between a Level 1 and Level 2 EV charger?',
    content: (
      <>
        <Typography variant="body1">
          Vehicle owners are often supplied with Level 1 chargers from
          automobile manufacturers at the time of purchase to use at home with
          120v standard outlets. A level 1 charger only adds 3-4 miles of range
          per hour of charging.
        </Typography>

        <Typography variant="body1">
          Upgrading to a Level 2 EV charger is a good and practical investment.
          A Level 2 charger is like having your own gas pump in your garage; it
          is a smart appliance that charges your vehicle up to 16 times faster
          than a Level 1 charger. An added convenience: not only is a Level 2
          car charger ready when you need it to be, you can save on electricity
          by charging during lower rate times.
        </Typography>
      </>
    )
  },
  {
    title: 'What should I know about Emporia’s Level 2 charger?',
    content: (
      <>
        <Typography variant="body1">
          Emporia’s award-winning Level 2 charger is one of the highest rated
          chargers on the market, labeled by MotorTrend as “The best smart home
          EV charger”. The Emporia Electric Vehicle Charging Station is a Level
          2 electric vehicle charger that charges any EV up to 40 amps with NEMA
          14-50P or up to 48 amps with hardwired installation. It comes with a
          24’ cable with an SAE J1772 connector
          <sup
            style={{
              fontWeight: 'bolder',
              fontStyle: 'italic'
            }}
          >
            *
          </sup>{' '}
          so it works with all electric cars. Its watertight black or white NEMA
          Type 4 enclosure is made to withstand the elements outdoors. Covered
          by a 3-year warranty when installed in North America per the
          instructions in the installation guide.
        </Typography>

        <br />
        <Typography variant="caption" fontStyle="italic">
          * Tesla and other manufacturers require a NACS to J1772 adaptor
        </Typography>
      </>
    )
  },
  {
    title: `Why do I need the Emporia app?`,
    content: (
      <>
        <Typography variant="body1">
          The Emporia app is used to complete the setup of your EV charger.
          Additionally, you can use the app to enroll in select utility
          programs, gain valuable insights into your EV charger energy usage,
          and even manage charging times to avoid high time-of-use periods and
          save you money!
        </Typography>

        <Typography variant="body1">
          In order to streamline the completion of the installation process,
          please download the app prior to your appointment by using this link:
          <a
            href="https://www.emporiaenergy.com/app"
            style={{ display: 'inline-block' }}
          >
            https://www.emporiaenergy.com/app
          </a>
          . Once downloaded, open the app and create an account. When your
          electrician completes installation, they will walk you through the
          final setup either on your phone or by using the installer app on
          theirs. Please ensure that this final setup is completed before
          attempting to plug in your charger.
        </Typography>
      </>
    )
  },
  {
    title: 'How long will it take for my charger to arrive?',
    content: (
      <Typography variant="body1">
        If you were not provided a charger by the dealership, Emporia will place
        your order and ship directly to the address you provide. Depending on
        your location, your order should arrive within 2-5 business days.
      </Typography>
    )
  },
  {
    title: 'What can I expect during the installation process?',

    content: (
      <List sx={{ listStyle: 'decimal', pl: 4, pt: 0, pb: 0 }}>
        <ListItem sx={{ display: 'list-item' }}>
          <ListItemText>
            You will receive a follow-up email from Emporia or one of its
            partners within 48 hours to confirm your order and provide a link to
            continue with the installation process to receive your no obligation
            quote.
          </ListItemText>
        </ListItem>

        <ListItem sx={{ display: 'list-item' }}>
          <ListItemText>
            You may be asked to provide photos or videos of your electrical
            panel and where you would like your charger installed. This is to
            help provide an accurate quote.
          </ListItemText>
        </ListItem>

        <ListItem sx={{ display: 'list-item' }}>
          <ListItemText>
            If you would like to proceed with installation after receiving your
            no obligation quote, you’ll schedule a date and time for
            installation. Please note that you may be required to pay a deposit
            to proceed with installation.
          </ListItemText>
        </ListItem>

        <ListItem sx={{ display: 'list-item' }}>
          <ListItemText>
            On the date of your appointment, the installer will arrive to
            complete the work and help complete the setup of your charger.
          </ListItemText>
        </ListItem>

        <ListItem sx={{ display: 'list-item' }}>
          <ListItemText>
            After installation is complete, if the installation cost exceeds the
            amount of your installation credit, your installer will provide you
            with an invoice for the remaining cost of installation after the
            credit has been applied. You will pay the installer directly.
          </ListItemText>
        </ListItem>

        <ListItem sx={{ display: 'list-item' }}>
          <ListItemText>
            Plug in your charger, open the Emporia app, and experience
            everything our award winning EV charger and app can do for you!
          </ListItemText>
        </ListItem>
      </List>
    )
  },
  {
    title: 'Who will be doing my installation?',
    content: (
      <Typography variant="body1">
        Emporia partners with electricians in every part of the country. All
        electricians have been vetted by Emporia or its partners to ensure that
        they are certified, qualified, and highly rated to ensure our Customers
        have a delightful installation experience. You will be provided the name
        and contact information of your electrician prior to your appointment
        date.
      </Typography>
    )
  },
  {
    title: 'How does my install credit work?',
    content: (
      <Typography variant="body1">
        The installation credit that you purchased as part of your EV Charger
        Bundle will be applied to the final cost of your installation. Your
        electrician has been notified of the amount of the credit and this
        should be reflected on your final invoice. If it is not, please contact
        Emporia at{' '}
        <a href="tel:844-367-6742" style={{ display: 'inline-block' }}>
          +1 (844) 367-6742
        </a>{' '}
        prior to payment.
      </Typography>
    )
  },
  {
    title: 'How much should I expect installation to cost?',
    content: (
      <>
        <Typography variant="body1">
          There are several factors that influence the cost of installation:
        </Typography>
        <List sx={{ listStyle: 'disc', pl: 4, pt: 0, pb: 0 }}>
          <ListItem sx={{ display: 'list-item' }}>
            <ListItemText>What state you live in</ListItemText>
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            <ListItemText>
              Where you want to have your charger installed
            </ListItemText>
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            <ListItemText>
              How far your electrical panel is from where you would like to have
              the charger installed
            </ListItemText>
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            <ListItemText>
              What type of home you are installing your charger in
            </ListItemText>
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            <ListItemText>Permitting and/or inspection costs</ListItemText>
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            <ListItemText>
              Available capacity and space in your electrical panel
            </ListItemText>
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            <ListItemText>
              Any custom work that must be done (e.g. trenching, sub-panel
              installation)
            </ListItemText>
          </ListItem>
        </List>
        <Typography variant="body1">
          The cost of installation will generally range between $1,000 - $2,500
          depending on the combination of the factors above, but in some
          specific situations that cost may be higher.
        </Typography>
      </>
    )
  },
  {
    title:
      // eslint-disable-next-line max-len
      'Who should I contact if I have questions during the installation process?',
    content: (
      <>
        <Typography variant="body1">
          If you have any questions about your charger or your installation,
          please contact us at{' '}
          <a href="tel:844-367-6742" style={{ display: 'inline-block' }}>
            +1 (844) 367-6742
          </a>{' '}
          on Monday-Friday between 9am-5pm MST. You can also email us anytime at
          autocharge@emporiaenergy.com.
        </Typography>
        <Typography variant="body1">
          For questions regarding the day of your appointment, or should you
          need to reschedule or cancel an appointment, please contact the
          electrician directly using the contact information you were provided
          prior to the day of your appointment.
        </Typography>
      </>
    )
  },
  {
    title: 'Who should I contact if I run into issues after installation?',
    content: (
      <>
        <Typography variant="body1">
          {/* eslint-disable-next-line max-len */}
          If you run into any issues after installation, please contact us at{' '}
          <a href="tel:844-367-6742" style={{ display: 'inline-block' }}>
            +1 (844) 367-6742
          </a>{' '}
          {/* eslint-disable-next-line max-len */}
          on Monday-Friday between 8am-5pm MST. You can also email us anytime at{' '}
          <a
            href="mailto:autocharge@emporiaenergy.com"
            style={{ display: 'inline-block' }}
          >
            autocharge@emporiaenergy.com
          </a>
          .
        </Typography>
        <Typography variant="body1">
          The support team at Emporia can help diagnose the issue and in some
          cases resolve the problem over the phone. In the event that a
          follow-up visit is required from the electrician, Emporia will
          coordinate with the electrician for that appointment. In most
          instances, you will not be charged if the electrician has been
          notified of the need for a follow-up visit within 30 days of
          installation.
        </Typography>
      </>
    )
  },
  {
    title: 'Are there any rebates, tax credits, or incentives available?',
    content: (
      <Typography variant="body1">
        There might be! Please use the following link to visit the US Department
        of Energy’s website outlining the laws and incentives, with instructions
        on how to apply:{' '}
        <a
          href="https://afdc.energy.gov/fuels/laws/ELEC"
          style={{ display: 'inline-block' }}
        >
          https://afdc.energy.gov/fuels/laws/ELEC
        </a>
      </Typography>
    )
  },
  {
    title: 'What is a main panel upgrade?',
    content: (
      <>
        <Typography variant="body1">
          A main panel upgrade is required when your electrical panel does not
          have enough capacity to handle all your home’s needs. A licensed
          electrician will upgrade your panel to increase its capacity so it can
          safely handle all your loads.
        </Typography>
        <Typography variant="body1">
          A main panel upgrade typically costs $2,500 - $5,000 and can take up
          to two months to complete depending on a variety of factors.
        </Typography>
      </>
    )
  }
];

const batterySolarFaqs: Faq[] = [
  {
    title: 'How quickly can my project be installed?',
    content: (
      <Typography variant="body1">
        Approximately 3 months after the contract is signed. Permits, utility
        agreements, logistics and scheduling all impact a project’s speed to
        installation. While it might seem long, 3 months allows us to ensure
        that all of the details are done correctly the first time.
      </Typography>
    )
  },
  {
    title: 'How accurate are your pricing estimates?',
    content: (
      <Typography variant="body1">
        We pride ourselves on giving you a fair, transparent and accurate price.
        Our proposals, following a design consultation and reviewing a few key
        pictures of your home, are quite accurate. That said, change orders can
        occur we do not guarantee a final project price until the site visit has
        been completed.
      </Typography>
    )
  },
  {
    title: 'Do I have to organize and schedule my own permits and inspections?',
    content: (
      <Typography variant="body1">
        Not at all. Those costs, including any applicable Utility
        interconnection fees are included in the cost of the project. Even
        better, the project manager will handle all of the paperwork. You may
        need to sign a form or two but we manage all aspects of the project.
      </Typography>
    )
  },
  {
    title: 'How does the contract and payment process work?',
    content: (
      <Typography variant="body1">
        Emporia will prepare your proposal and (if you choose to continue with
        the project) the contract too. The contract is our certified installer’s
        contract and includes all payment terms and responsibilities. You will
        pay the installer or their financing partner for the equipment and
        installation.
      </Typography>
    )
  },
  {
    title: 'What are my payment options?',
    content: (
      <Typography variant="body1">
        Cash, a personal loan (from a HELOC, Credit Union or other), a loan from
        a renewables finance company and in some cases a solar lease. Speak to
        our Electrification Experts to learn more.
      </Typography>
    )
  },
  {
    tag: 'intelligent-home-backup',
    title: 'What is Intelligent Home Backup?',
    content: (
      <Typography variant="body1">
        Intelligent home backup (IHB) makes the most out of your battery. It
        allows you to power your home from any of (or a combination of) the
        following: solar power, battery power or grid power. IHB even supports a
        generator input if you really need resilience. Best of all, IHB comes
        with dynamic load control meaning that it can turn off large loads like
        water heaters, hot tubs, or hvac units so conserve your battery to keep
        things like lights, appliances and life-safety equipment running.
      </Typography>
    )
  },
  {
    title: 'Tell me about the product warranties.',
    content: (
      <Typography variant="body1">
        The solar panels that we include have a 25 year warranty. The inverters,
        batteries and grid disconnect devices have a 12 year manufacturer’s
        warranty with an option to extend them to 15
      </Typography>
    )
  },
  {
    title: 'Are the warranties transferable if I sell my home?',
    content: (
      <Typography variant="body1">
        Yes, the warranties are transferable if you sell your home. Emporia
        customer service will be able to assist if and when this is necessary.
      </Typography>
    )
  }
];
